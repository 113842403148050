import {
    Vector3,
    Scene,
    UniversalCamera,
    ActionManager,
    PointerEventTypes,
    setAndStartTimer
} from "@babylonjs/core";
import "hammerjs";
import {CustomJoyInput} from "@/js/customJoyInput";


export class Player extends UniversalCamera {

    moveTimer: null

    constructor(name: string, position: Vector3, scene: Scene) {
        super(name, position, scene)
        //键位映射
        this.inputs.clear()
        this.inputs.addGamepad()
        this.inputs.addKeyboard()
        this.inputs.addMouse(true)
        this.inputs.add(new CustomJoyInput())


        this.keysUp.push(87)
        this.keysDown.push(83)
        this.keysLeft.push(65)
        this.keysRight.push(68)
        //碰撞和重力
        this.checkCollisions = true
        this.applyGravity = true
        console.log(this.touchAngularSensibility)


        this.fovMode = Player.FOVMODE_VERTICAL_FIXED
        this.fov = Math.PI * 0.45
        this.minZ = 0.1

        this.speed = 0.2
        this.inertia = 0.8
        this.angularSensibility = 500
        this.ellipsoid = new Vector3(0.3, 1, 0.3);

        scene.actionManager = new ActionManager(scene)
        console.log(scene.actionManager)

        scene.onPointerObservable.add(e => {
            if (e.type === PointerEventTypes.POINTERPICK) {
                if (Vector3.Dot(e.pickInfo.getNormal(), Vector3.Up()) > -0.1) {
                    let point = e.pickInfo.pickedPoint
                    let normal = e.pickInfo.getNormal(true)
                    this.TeleportTo(point.add(normal.scale(1)))
                }

            }
        })


    }

    TeleportTo(target: Vector3) {
        let startPos = this.position.clone();
        let endPos = target.clone().add(Vector3.Up().scale(2));
        let len = Vector3.Distance(startPos, endPos)
        let lastRate = 0;
        this.moveTimer = setAndStartTimer({
            timeout: 750,
            contextObservable: this._scene.onBeforeRenderObservable,
            onTick: (e) => {
                let delta = e.completeRate - lastRate
                let translate = endPos.subtract(this.position).normalizeToNew().multiply(new Vector3(1, 0, 1).scale(delta * len))
                this.cameraDirection = translate
                this._updatePosition()
                lastRate = e.completeRate
            }
        })

    }

}


