import {Observable, Scene} from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";

const sportList = [
    {name: '花瓶1', url: 'https://www.ll3d.com/model/8a99686f7d5094c7017dc1e9b6010a08?nobackground=true'},
    {name: '花瓶2', url: 'https://www.ll3d.com/model/8a99686f7d5094c7017dc1e9b6010a08?nobackground=true'},
    {name: '花瓶3', url: 'https://www.ll3d.com/model/8a99686f7d5094c7017dc1e9b6010a08?nobackground=true'},
    {name: '花瓶4', url: 'https://www.ll3d.com/model/8a99686f7d5094c7017dc1e9b6010a08?nobackground=true'},
    {name: '花瓶5', url: 'https://www.ll3d.com/model/8a99686f7d5094c7017dc1e9b6010a08?nobackground=true'},
    {name: '花瓶6', url: 'https://www.ll3d.com/model/8a99686f7d5094c7017dc1e9b6010a08?nobackground=true'},
]


export class HotSportManage {
    onSportClickObservable: Observable<{ name: string, url: string }>

    constructor(scene: Scene) {
        this.scene = scene
        this.onSportClickObservable = new Observable()
        this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('ui')
    }

    async update() {

        this.sportList = sportList
        this.sportList.forEach(item => {
            let hotSport =  GUI.Button.CreateSimpleButton('but')
            hotSport.width = "20px"
            hotSport.height = "20px"
            hotSport.thickness = 3
            hotSport.cornerRadius = 360
            let mesh = this.scene.getNodeByName(item.name)
            if (mesh) {
                hotSport.onPointerClickObservable.add(e => {
                    this.onSportClickObservable.notifyObservers(item)
                })
                this.advancedTexture.addControl(hotSport)
                hotSport.linkWithMesh(mesh)
            }
        })

    }
}
