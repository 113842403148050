import {
    Color3,
    Mesh,
    Node,
    PBRMaterial,
    Scene,
    StandardMaterial,
    Tags,
    Texture,
    Tools,
    VideoTexture
} from "@babylonjs/core";
import axios from "axios"
import {getQueryVariable} from "@/js/tool";


async function getGalleryConfig() {
    let response = await axios.get("./template/" + getQueryVariable("tem_id") + "/config.json")
    return response.data
}

export const locateType = {
    TEXT: 1,
    IMG: 2,
    VIDEO: 3,
    AUDIO: 4,
    MODEL: 5,
}

export class GalleryManage {
    constructor(scene: Scene) {
        this.scene = scene
        this.init()
    }

    async init() {
        this.locates = []
        let config = await getGalleryConfig()
        config.send_locate.forEach(e => {
            let node = this.scene.getNodeByName(e.title)
            if (node) {
                this.locates.push(new GalleryLocate(e.title, e.locate_type, node))
            }
        })
        this.update()
    }

    update() {
        this.locates.forEach((locate) => {
            locate.update()
        })
    }
}

export class GalleryLocate {
    constructor(title: string, locate_type: number, node: Node) {
        this.title = title
        this.locate_type = locate_type
        this.node = node
    }

    async update() {
        let res = await axios.get("https://ky3d.com/addons/yb_guanwang/core/index.php/api/index/getTemplateData", {
            params: {
                mch_id: getQueryVariable('mch_id'),
                title: this.title,
                tem_id: getQueryVariable('tem_id')

            }
        })
        let url = res.data.data.source
        let state = res.data.data.status === 1 //1为有, 2为无
        if (url) {
            url = url.replace('https://ky3d.com', 'wapapi')
        }
        this.locate_type = res.data.data.locate_type

        switch (this.locate_type) {
            case locateType.TEXT:
                break;
            case locateType.IMG:
                if (this.node instanceof Mesh && state) {
                    let mat = this.node.material
                    if (mat instanceof StandardMaterial) {
                        mat.diffuseTexture = new Texture(url, this.node._scene, false, false)
                    } else if (mat instanceof PBRMaterial) {
                        mat.albedoTexture = new Texture(url, this.node._scene, false, false)
                    }
                }
                break;
            case locateType.AUDIO:
                break;
            case locateType.VIDEO:
                if (this.node instanceof Mesh && state && url !== "素材视频地址") {
                    let mat = this.node.material
                    if (mat instanceof StandardMaterial) {
                        mat.emissiveTexture = new VideoTexture(this.title + "_video", url, this.node._scene, false, true)
                    } else if (mat instanceof PBRMaterial) {
                        let tex = new VideoTexture(this.title + "_video", url, this.node._scene, false, true,)
                        mat.albedoTexture = tex
                        mat.emissiveTexture = tex
                        mat.emissiveIntensity = 1
                        mat.emissiveColor = Color3.White()
                        tex.video.muted = true
                        tex.video.play()
                        mat.roughness = 0.1


                    }
                }
                break;
            case locateType.MODEL:
                break;

        }
    }
}
